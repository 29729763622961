import './Loading.scss'

const Loading=()=>{
  return(
    <body className='background'>
        <div className='back'>
            <span className='type'>
                National Cheng Kung University
                Google Developer Student Club ...
            </span>
        </div>
    </body>
  )
}
export default Loading;